import { FC, useCallback, useMemo } from 'react';
import appointmentAPI from '../../services/AppointmentService';
import LoadingSpinner from '../LoadingSpinner';
import RecurringEventForm, { RecurringEventFormType } from './components/RecurringEventForm';
import { useAppDispatch } from '../../hooks/redux';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import RecurringEventCustomersForm from './components/RecurringEventCustomersForm';

type EditRecurringEventProps = {
    id: number | string;
    entry_date: string;
    onClose: () => void;
    customersOnly?: boolean;
};

const EditRecurringEvent: FC<EditRecurringEventProps> = ({ id, entry_date, onClose, customersOnly }) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const { data, isLoading } = appointmentAPI.useGetRecurringEventByIdQuery({ eventId: id, entryDate: entry_date });
    const [save] = appointmentAPI.useUpdateRecurringEventMutation();

    const defaults = useMemo(() => {
        if (data) {
            const { location, customers, employee, ...rest } = data;
            return {
                ...rest,
                employee_id: employee.id,
                location_id: location.id,
                customers: customers.map((customer) => ({
                    id: customer.id,
                    is_paid: customer.pivot.is_paid,
                    title: `${customer.firstname} ${customer.lastname}`
                }))
            };
        }

        return null;
    }, [data]);

    const onSubmit = useCallback(
        (formData: RecurringEventFormType) => {
            const { employee_id, ...rest } = formData;
            if (employee_id) {
                dispatch(startSubmitting());
                save({ ...rest, employee_id, entry_date, recurring_event_id: id })
                    .unwrap()
                    .then(() => {
                        onClose();
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Success,
                            message: 'Event Updated Successfully!'
                        });
                    })
                    .catch((err) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Error,
                            message: err.message || JSON.stringify(err)
                        });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [dispatch, entry_date, id, onClose, save, showSnackbar]
    );

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (defaults && !!customersOnly) {
        return <RecurringEventCustomersForm defaults={defaults} onSubmit={onSubmit} />;
    }

    return <>{defaults && <RecurringEventForm defaults={defaults} onSubmit={onSubmit} hideCustomers blockEditPaymentType />}</>;
};

export default EditRecurringEvent;
