import { FC, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import useWebhookDefaults from './use-webhook-defaults';
import companyAPI from '../../../services/CompanyService';
import { useAppDispatch } from '../../../hooks/redux';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { IWebhookSettingsInput } from '../types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WebhookDialog from './WebhookDialog';

const NewWebhook: FC<{ companyId: number }> = ({ companyId }) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const { defaultValue, triggerData } = useWebhookDefaults(companyId);

    const [save, { isLoading: isSaving }] = companyAPI.useCreateCompanyWebhookMutation();
    const { showSnackbar } = useShowSnackbar();

    const handleCreate = useCallback(
        (data: IWebhookSettingsInput) => {
            if (!isSaving) {
                dispatch(startSubmitting());
                save({ companyId, data })
                    .unwrap()
                    .then((res) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Success,
                            message: 'Web Hook Created'
                        });
                        setOpen(false);
                    })
                    .catch((err) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Error,
                            message: err.message ?? JSON.stringify(err)
                        });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [companyId, dispatch, isSaving, save, showSnackbar]
    );

    if (!triggerData) return null;

    return (
        <>
            <Button
                size="small"
                color="primary"
                className="forcedBg"
                disableElevation
                onClick={() => setOpen(true)}
                endIcon={<AddCircleIcon />}
            >
                Add Hook
            </Button>
            <WebhookDialog
                open={open}
                onClose={() => setOpen(false)}
                dialogTitle="Add Web Hook"
                defaults={defaultValue}
                onSubmit={handleCreate}
                triggerData={triggerData}
                dialogActionsDisabled={isSaving}
                dialogOkButtonLabel="Create"
            />
        </>
    );
};

export default NewWebhook;
