import { useCallback, useEffect, useMemo, useState, FocusEvent } from 'react';
import { useAppDispatch } from '../../hooks/redux';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import moment from 'moment';
import { asyncFilesDeleteAndUpload } from '../../utils/functions/uploading-images-helpers';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { Box, FormControlLabel, FormHelperText, Grid, Link, Stack, Switch, TextField } from '@mui/material';
import CustomerSelect from './elements/customer-select';
import StatusSelect from './elements/status-select';
import ProviderSelect from './elements/ProviderSelect';
import DateTime from './elements/DateTime';
import { IEmployee, UserRole } from '../../models/IEmployee';
import { IService, PaymentType, ServiceAnswerType, ServiceLocationType } from '../../models/IService';
import { ICustomer } from '../../models/ICustomer';
import { AppointmentFormType, AppointmentStatuses, IAppointmentPayload, IDepositRequestPayload } from '../../models/IAppointment';
import { Fade } from '@material-ui/core';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import useShouldSubmitDetailsForm from '../../hooks/useShouldSubmitDetailsForm';
import useAppointmentDetailsFormFunctions from '../../hooks/useAppointmentDetailsFormFunctions';
import useAuth from '../../hooks/useAuth';
import DepositRequestForm from '../form/deposit-request/DepositRequestForm';
import { cancellationReasons } from '../../store/constant';
import DateAutocomplete from '../DateAutocomplete';
import useFreeDateInput from '../../hooks/useFreeDateInput';
import useAppointmentEditRestrictions from '../../hooks/useAppointmentEditRestrictions';
import AppointmentServicesSubform from './elements/appointment-services-subform';
import { getMaterialsBasedServicePrice, getServiceArrayDuration, isAllServiceExternal, isAllServiceVirtual } from '../../utils/services';
import AppointmentAddressBox from '../AppointmentAddressBox';
import { IAddress } from '../../models/IAddress';
import AppointmentNotes from './elements/AppointmentNotes';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import ConditionalDialog from '../conditional-dialog/ConditionalDialog';
import AppointmentImageGallery from '../appointment-image-gallery';
import useImagesManagement from '../../hooks/use-images-management';
import appointmentSchema from './scheme';
import useEntityDrawerSpacing from '../entity-drawer-layout/use-entity-drawer-spacing';
import { useAppointmentFunctions, useSaveAppointment } from '../../hooks/appointments';
import SectionHeading from '../SectionHeading';
import { ApiRequestError } from '../../utils/axios';

export interface FormFieldProps {
    values: FormikValues;
    setFieldValue: (fieldName: string, value: any) => void;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    setFieldTouched: (a: string) => void;
    handleBlur: (e: FocusEvent<any>) => void;
    disabled?: boolean;
}

interface IAppointmentFormProps {
    data: AppointmentFormType;
    callback?: () => void;
    isDepositRequested?: boolean;
    handleCancelDepositRequest?: () => void;
    isNew?: boolean;
    onStartDateChange?: (date: Date) => void;
    isReadOnly?: boolean;
}

const AppointmentForm = ({
    data,
    callback,
    isDepositRequested,
    handleCancelDepositRequest,
    isNew,
    onStartDateChange,
    isReadOnly = false
}: IAppointmentFormProps) => {
    const { spacingX } = useEntityDrawerSpacing();
    const [dialog, setDialog] = useState(false);
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { saveAppointment, isSavingAppointment } = useSaveAppointment();
    const { showSnackbar } = useShowSnackbar();
    const { deletedImages, visibleImages, onAddNewImage, onDeleteImage, imagesToUpload } = useImagesManagement(data.images);
    const { formDataToAppointmentPayload, isAppointmentFormChanged, calculateAppointmentServicesDuration } = useAppointmentFunctions();

    const handleDrop = useCallback(
        (files: File[]) => {
            files.forEach((file) => {
                onAddNewImage(file);
            });
        },
        [onAddNewImage]
    );

    const isProvider = useMemo(() => user?.employee.role.name === UserRole.Provider, [user]);

    const {
        touched,
        setTouched,
        errors,
        setErrors,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        values,
        handleSubmit,
        validateForm,
        setFieldError
    } = useExtendedFormik<AppointmentFormType>({
        enableReinitialize: true,
        initialValues: data,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: appointmentSchema,
        onSubmit: (formData: AppointmentFormType) => {
            if (!data || isSavingAppointment) return;
            const timezone = formData?.location?.time_zone || 'utc';
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { address, ...restFormData } = formData;
            const payload = isExternalAppointment ? formData : restFormData;
            if (!formData?.id && moment.tz(formData.start_at, timezone) <= moment().tz(timezone)) {
                dispatch(
                    openConfirmPopup({
                        onConfirm: () => {
                            handleSave(payload);
                        },
                        onClose: () => {
                            dispatch(stopSubmitting());
                        },
                        title: 'Past Date Appointment',
                        confirmText: 'Create',
                        text: `This date has already passed. Are you sure you'd like to create the appointment?`
                    })
                );
            } else {
                handleSave(payload);
            }
        },
        isBlocked: !data || isSavingAppointment
    });

    const selectedServices = useMemo<IService[]>(
        () => values.services.reduce<IService[]>((acc, current) => (current.service ? [...acc, current.service] : acc), []),
        [values.services]
    );

    const isVirtualAppointment = useMemo(() => isAllServiceVirtual(selectedServices), [selectedServices]);
    const isExternalAppointment = useMemo(() => isAllServiceExternal(selectedServices), [selectedServices]);

    const useRebookReminder = useMemo(() => values.services.some(({ service }) => !!service?.use_rebook_reminder), [values.services]);

    const { canEditKeyFields } = useAppointmentEditRestrictions();

    const toggleRebookReminder = useCallback(
        (value: boolean) => {
            setFieldValue('use_reminder', value);
            setFieldValue('remind_after_interval', value ? '1 week' : '');
        },
        [setFieldValue]
    );

    const updateRemindAt = useCallback(
        (value: string | null) => {
            setFieldValue('remind_after_interval', value);
        },
        [setFieldValue]
    );

    const { suggestion } = useFreeDateInput(values.remind_after_interval ?? '');
    const isMultiServicesEnabled = !!user?.currentCompany.settings?.widget?.use_multiservices;

    const handleDepositCancel = useCallback(() => {
        setDialog(false);
        if (handleCancelDepositRequest) {
            handleCancelDepositRequest();
            setFieldValue('deposit_request', null);
        }
    }, [handleCancelDepositRequest, setFieldValue]);

    const handleDepositRequest = useCallback(
        (depositData: IDepositRequestPayload) => {
            setFieldValue('deposit_request', depositData);
            handleCancelDepositRequest?.();
            setDialog(false);
            handleSubmit();
        },
        [handleCancelDepositRequest, handleSubmit, setFieldValue]
    );

    const handleSave = useCallback(
        (formData: AppointmentFormType) => {
            dispatch(startSubmitting());
            asyncFilesDeleteAndUpload(imagesToUpload, deletedImages)
                .then((res) => {
                    const oldImages = visibleImages.map((img) => ('link' in img ? img.link : '')).filter((link) => !!link);
                    const urls = [...res.map(({ link }) => link)];
                    const images = [...oldImages, ...urls];

                    const payload: IAppointmentPayload = {
                        ...formDataToAppointmentPayload(formData),
                        images
                    };

                    saveAppointment(
                        payload,
                        () => {
                            showSnackbar({
                                message: 'Appointment saved successfully',
                                alertSeverity: SnackBarTypes.Success
                            });
                            setTouched({}, false);

                            if (callback) callback();
                        },
                        data.id ? data.id.toString() : undefined,
                        () => {
                            handleDepositCancel();
                            dispatch(stopSubmitting);
                        },
                        (e: ApiRequestError) => {
                            if (e.errors) {
                                Object.entries(e.errors).forEach(([key, value]) => {
                                    const errKey = key.replace('service_ids', 'services');
                                    const errValue = typeof value === 'string' ? value : value.join(', ');

                                    setFieldError(errKey, errValue);
                                });
                            }
                        }
                    );
                })
                .catch((err: ApiRequestError) => {
                    showSnackbar({
                        message: err.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [
            callback,
            data.id,
            deletedImages,
            dispatch,
            formDataToAppointmentPayload,
            handleDepositCancel,
            imagesToUpload,
            saveAppointment,
            setFieldError,
            setTouched,
            showSnackbar,
            visibleImages
        ]
    );

    const appointmentAnswers = useMemo(() => {
        const arr: Array<IService | undefined | null> = (data.services ?? []).map(({ service }) => service);
        return arr.reduce<ServiceAnswerType[]>((acc, current) => {
            const answers = current?.pivot?.widget_answers;
            if (answers && answers.length > 0) {
                return [...acc, ...answers];
            }

            return acc;
        }, []);
    }, [data.services]);

    const hasChanges = useMemo(() => {
        const hasAttachmentsChanged = values.images.length !== visibleImages.length || !!deletedImages.length;

        return isAppointmentFormChanged(values, data) || hasAttachmentsChanged;
    }, [values, visibleImages.length, deletedImages.length, isAppointmentFormChanged, data]);

    const combinedDuration = useMemo(() => {
        const servicesArray: IService[] = [];
        const optionsDuration = calculateAppointmentServicesDuration(values.services);
        values.services.forEach(({ service, options }) => {
            if (service) servicesArray.push(service);
        });

        return getServiceArrayDuration(servicesArray, values.employee) + optionsDuration;
    }, [calculateAppointmentServicesDuration, values.employee, values.services]);

    const depositRequestAmount = useMemo(
        () =>
            values.services.reduce<number>((prev, currentValue) => {
                if (currentValue?.service?.payment_type === PaymentType.Prepaid) {
                    return prev + (currentValue?.prepay ?? 0);
                }

                if (currentValue?.service?.payment_type === PaymentType.Paid) {
                    return prev + (currentValue?.price ?? 0);
                }

                return prev;
            }, 0),
        [values.services]
    );

    const handleChangeProvider = useCallback(
        (newValue: IEmployee | null) => {
            setFieldValue('employee', newValue);
            if (newValue) {
                setFieldTouched('employee', false);
            }

            if (isNew) {
                const newServices = values.services.map((serviceRow) => {
                    if (serviceRow.service) {
                        return {
                            ...serviceRow,
                            price: getMaterialsBasedServicePrice(serviceRow.service, newValue?.services || [], serviceRow.materials_amount)
                        };
                    }
                    return serviceRow;
                });
                setFieldValue('services', newServices);
            }
        },
        [isNew, setFieldTouched, setFieldValue, values.services]
    );

    const handleChangeCustomer = useCallback(
        (newCustomer: ICustomer | null) => {
            setFieldValue('customer', newCustomer);
            if (isExternalAppointment) {
                setFieldValue('address', newCustomer?.latest_appointment_address || null);
            }
        },
        [isExternalAppointment, setFieldValue]
    );

    const onSelectServiceCb = useCallback(
        (service: IService) => {
            if (
                !values.address &&
                !!values.customer?.latest_appointment_address &&
                service?.location_type === ServiceLocationType.External
            ) {
                setFieldValue('address', values.customer.latest_appointment_address ?? '');
            }
        },
        [setFieldValue, values.address, values.customer]
    );

    const handleValidation = useCallback(() => {
        validateForm()
            .then((res) => {
                setErrors(res);
                const errorKeys = Object.keys(res);
                if (errorKeys.length) {
                    handleDepositCancel();
                    setDialog(false);
                    errorKeys.forEach((key) => {
                        if (key === 'services') {
                            // Services is array of objects, better set touched fields based on actual value
                            values.services.forEach((s, index) => {
                                for (const field of Object.keys(s)) {
                                    setFieldTouched(`services.${index}.${field}`, true, false);
                                }
                            });
                        } else {
                            setFieldTouched(key, true, false);
                        }
                    });
                } else {
                    setDialog(true);
                }
            })
            .catch((err) => {
                handleDepositCancel();
                console.error(err);
            });
    }, [handleDepositCancel, setErrors, setFieldTouched, validateForm, values.services]);

    const addressError = useMemo(() => {
        if (errors.address && typeof errors.address === 'object') {
            // Because Formik can't recognize correct error type
            // @ts-ignore
            return errors.address.address;
        }

        return errors.address;
    }, [errors.address]);

    useEffect(() => {
        if (!dialog && isDepositRequested) {
            handleValidation();
        }
    }, [dialog, handleValidation, isDepositRequested]);

    useEffect(() => {
        if (values.start_at && onStartDateChange) {
            onStartDateChange(new Date(values.start_at));
        }
    }, [values.start_at, onStartDateChange]);

    useEffect(() => {
        const noDuration = data.start_at === data.end_at;

        if (noDuration) {
            setFieldValue('duration', combinedDuration);
            setFieldValue('end_at', moment(values.start_at).add(combinedDuration, 'minutes').toISOString());
        }
    }, [combinedDuration, data.end_at, data.start_at, setFieldValue, values.start_at]);

    useAppointmentDetailsFormFunctions('appointment-form', isSavingAppointment);
    useShouldSubmitDetailsForm(!isNew ? hasChanges : false, false);

    return (
        <Box px={spacingX}>
            <Fade in={!dialog}>
                <Box sx={dialog ? { position: 'absolute', width: 0, height: 0, opacity: 0 } : undefined}>
                    <Box id="newCustomerFormContainer" sx={{ height: 0, overflow: 'hidden' }} />
                    <Grid container spacing={3} component="form" noValidate onSubmit={handleSubmit} id="appointment-form">
                        <Grid item xs={12}>
                            <Stack spacing={2} direction="row" sx={{ width: '100%' }}>
                                <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
                                    <CustomerSelect
                                        customer={values.customer}
                                        handleBlur={handleBlur}
                                        error={errors.customer}
                                        touched={touched.customer}
                                        onChange={handleChangeCustomer}
                                        disabled={isReadOnly || !canEditKeyFields(values.status)}
                                    />
                                </Box>
                                {!!values?.id && values?.status ? (
                                    <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                                        <StatusSelect
                                            appointmentId={values.id}
                                            value={values.status}
                                            onChange={(v) => {
                                                setFieldValue('status', v);
                                            }}
                                            disabled={isReadOnly}
                                            useRebookReminder={useRebookReminder}
                                        />
                                    </Box>
                                ) : null}
                            </Stack>
                        </Grid>

                        {isVirtualAppointment && data.cbvc_url ? (
                            <Grid item xs={12}>
                                <SectionHeading mb={0.5}>Link to video call</SectionHeading>
                                <Link href={data.cbvc_url} target="_blank">
                                    {data.cbvc_url}
                                </Link>
                            </Grid>
                        ) : null}

                        {isExternalAppointment ? (
                            <Grid item xs={12} pt={1}>
                                <AppointmentAddressBox
                                    fullWidth
                                    label="External Address"
                                    id="address"
                                    name="address"
                                    onChange={(address: IAddress | null) => setFieldValue('address', address)}
                                    onBlur={handleBlur}
                                    value={values.address}
                                    error={touched.address && !!addressError}
                                    helperText={touched.address ? addressError : undefined}
                                />
                            </Grid>
                        ) : null}

                        {data.status === AppointmentStatuses.Canceled && data.cancel_reason ? (
                            <Grid item xs={12}>
                                <SectionHeading mb={0.5}>Cancellation Reason</SectionHeading>
                                <TextField disabled value={cancellationReasons[data.cancel_reason].title} />
                            </Grid>
                        ) : null}

                        {values.status === AppointmentStatuses.Completed ? (
                            <>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        sx={{
                                            ml: 0,
                                            width: '100%',
                                            justifyContent: 'space-between'
                                        }}
                                        control={<Switch checked={values.use_reminder} />}
                                        onChange={(_, checked) => toggleRebookReminder(checked)}
                                        label="Rebook Reminder"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                {values.use_reminder && (
                                    <Grid item sm={6} xs={12}>
                                        <DateAutocomplete
                                            value={values.remind_after_interval ?? ''}
                                            onChange={updateRemindAt}
                                            suggestion={suggestion}
                                            error={Boolean(errors.remind_after_interval)}
                                            helperText={errors.remind_after_interval}
                                        />
                                    </Grid>
                                )}
                            </>
                        ) : null}

                        {!isProvider ? (
                            <Grid item xs={12}>
                                <ProviderSelect
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    disabled={isReadOnly || !canEditKeyFields(values.status)}
                                    onChange={handleChangeProvider}
                                    whitelist={data.employee?.id ? [data.employee?.id] : []}
                                    location_id={data.location?.id}
                                />
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <AppointmentServicesSubform
                                value={values.services}
                                setValue={setFieldValue}
                                setTouched={setFieldTouched}
                                valuePrefix="services"
                                errors={errors.services}
                                touched={touched.services}
                                disabled={isReadOnly || !canEditKeyFields(values.status)}
                                employeeServices={values.employee?.services}
                                useMultiservices={isMultiServicesEnabled}
                                serviceSelectCb={onSelectServiceCb}
                            />
                            {typeof errors.services === 'string' && <FormHelperText error>{errors.services}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12}>
                            <SectionHeading mb={0.5}>Date</SectionHeading>
                            <DateTime
                                values={values}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                                disabled={isReadOnly}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SectionHeading mb={0.5}>Attachments</SectionHeading>
                            <AppointmentImageGallery value={visibleImages} onDrop={handleDrop} onDelete={onDeleteImage} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppointmentNotes
                                isDisabled={isReadOnly}
                                formikInstance={{
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    setFieldValue,
                                    setFieldTouched
                                }}
                                showCustomerNotes={!!data.note}
                                answers={appointmentAnswers}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
            {isNew && (
                <ConditionalDialog
                    open={dialog}
                    title="Request Deposit"
                    onCancel={handleDepositCancel}
                    formId="deposit-request-form"
                    okButtonLabel="Request"
                >
                    <DepositRequestForm
                        onSubmit={handleDepositRequest}
                        defaults={{
                            expires_at: values.start_at ?? undefined,
                            amount: depositRequestAmount ?? ''
                        }}
                    />
                </ConditionalDialog>
            )}
        </Box>
    );
};

export default AppointmentForm;
