import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionProps, Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import FormAccordionSummary from './FormAccordionSummary';

const accordionSx = {
    width: '100%',

    '&.MuiAccordion-root.MuiPaper-root:before': {
        backgroundColor: 'grey.200'
    },

    '.Mui-expanded + &.Mui-expanded': {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
    },

    '&.Mui-expanded': {
        mb: 0,

        '&:before': {
            opacity: 1
        }
    },

    '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        maxWidth: '100%'
    },

    '& .MuiAccordionSummary-root': {
        fontSize: '1rem',
        fontWeight: 600,
        color: 'grey.900',
        pl: 0,
        pr: 0,
        minHeight: '56px',
        width: '100%',

        '&.Mui-expanded': {
            minHeight: '56px !important'
        }
    },
    '&.hasError .MuiAccordionSummary-root': {
        color: 'error.main'
    },

    '& .MuiAccordionDetails-root': { px: 0, py: 2, width: '100%' }
} as const;

const FormAccordion: FC<AccordionProps & { label: ReactNode }> = ({ children, label, sx, ...rest }) => {
    const [hasErrors, setHasErrors] = useState(false);
    const accordionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (accordionRef.current) {
            const error = accordionRef.current?.querySelector('.Mui-error');
            setHasErrors((prev) => (prev !== Boolean(error) ? Boolean(error) : prev));
        }
    }, [children]);

    return (
        <Accordion ref={accordionRef} disableGutters sx={{ ...accordionSx, ...sx }} {...rest}>
            <FormAccordionSummary>
                <>{label}</>
                {hasErrors ? (
                    <Chip
                        sx={{
                            backgroundColor: '#F86F60',
                            borderRadius: '8px',
                            fontWeight: 400,
                            height: 'auto',
                            py: '6px',
                            ml: 1,
                            '& .MuiSvgIcon-root': {
                                fontSize: '1rem',
                                marginLeft: '16px'
                            },

                            '& .MuiChip-label': {
                                paddingRight: '16px'
                            }
                        }}
                        color="error"
                        icon={<InfoIcon />}
                        label="Missing Information"
                    />
                ) : null}
            </FormAccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default FormAccordion;
