import { Box, Button, Stack } from '@mui/material';
import appointmentAPI from '../../../../../../../services/AppointmentService';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { skipToken } from '@reduxjs/toolkit/query';
import AppointmentProductForm, { AppointmentProductFormType } from './AppointmentProductForm';
import { useCallback, useMemo, useState } from 'react';
import { openConfirmPopup } from '../../../../../../../store/confirmPopupSlice';
import { IAppointmentProduct } from '../../../../../../../models/IProduct';
import AppointmentProductsTable from './AppointmentProductsTable';
import useShowSnackbar from '../../../../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../../../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../../../../../../store/slices/SubmittingSlice';
import useProductsOptions from '../../../../../../../hooks/options/useProductsOptions';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import useEntityDrawerSpacing from '../../../../../../entity-drawer-layout/use-entity-drawer-spacing';
import PermanentEntityDrawer from '../../../../../../PermanentEntityDrawer';
import SectionHeading from '../../../../../../SectionHeading';

const AppointmentProducts = () => {
    const { spacingX } = useEntityDrawerSpacing();
    const [creating, setCreating] = useState(false);
    const [editing, setEditing] = useState<IAppointmentProduct | null>(null);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const selectedEventId = selectedEvent?.id;

    const { data, isLoading } = appointmentAPI.useListAppointmentProductsQuery(
        selectedEventId ? { appointmentId: selectedEventId } : skipToken
    );

    const products = useMemo<IAppointmentProduct[]>(() => data ?? [], [data]);
    const { options } = useProductsOptions(products.map(({ id }) => id));

    const [saveAppointmentProduct, { isLoading: isSaving }] = appointmentAPI.useCreateAppointmentProductMutation();
    const [updateAppointmentProduct, { isLoading: isUpdating }] = appointmentAPI.useUpdateAppointmentProductMutation();
    const [deleteAppointmentProduct] = appointmentAPI.useDeleteAppointmentProductMutation();

    const showMainContent = useMemo(() => !creating && !editing, [creating, editing]);
    const canAddProduct = useMemo(
        () => showMainContent && !!options.length && !isForeignAppointment,
        [showMainContent, options.length, isForeignAppointment]
    );

    const handleError = useCallback(
        (err: any) => {
            showSnackbar({
                alertSeverity: SnackBarTypes.Error,
                message: err.message ?? JSON.stringify(err)
            });
        },
        [showSnackbar]
    );

    const handleCreateProduct = useCallback(
        (formData: AppointmentProductFormType) => {
            if (selectedEventId) {
                const { productId, ...rest } = formData;
                saveAppointmentProduct({
                    appointmentId: selectedEventId,
                    productId,
                    payload: rest
                })
                    .unwrap()
                    .then((result) => {
                        setCreating(false);
                        dispatch(
                            appointmentAPI.util?.updateQueryData(
                                'listAppointmentProducts',
                                {
                                    appointmentId: selectedEventId
                                },
                                (prev) => (!prev.some((i) => i.id === result.id) ? [...prev, result] : prev)
                            )
                        );
                    })
                    .catch((err) => {
                        handleError(err);
                    });
            }
        },
        [selectedEventId, saveAppointmentProduct, dispatch, handleError]
    );

    const onUpdateProduct = useCallback((product: IAppointmentProduct) => {
        setEditing(product);
    }, []);

    const handleUpdateProduct = useCallback(
        (formData: AppointmentProductFormType) => {
            console.log('submit!');
            if (selectedEventId) {
                const { productId, ...rest } = formData;
                updateAppointmentProduct({
                    appointmentId: selectedEventId,
                    productId,
                    payload: rest
                })
                    .unwrap()
                    .then((result) => {
                        setEditing(null);
                        dispatch(
                            appointmentAPI.util?.updateQueryData(
                                'listAppointmentProducts',
                                {
                                    appointmentId: selectedEventId
                                },
                                (prev) => prev.map((i) => (i.id === result.id ? result : i))
                            )
                        );
                    })
                    .catch((err) => {
                        handleError(err);
                    });
            }
        },
        [selectedEventId, updateAppointmentProduct, dispatch, handleError]
    );

    const handleDeleteProduct = useCallback(
        (productId: number) => {
            if (selectedEventId) {
                dispatch(startSubmitting());
                deleteAppointmentProduct({ appointmentId: selectedEventId, productId })
                    .unwrap()
                    .then(() => {
                        dispatch(
                            appointmentAPI.util?.updateQueryData(
                                'listAppointmentProducts',
                                {
                                    appointmentId: selectedEventId
                                },
                                (prev) => prev.filter((i) => i.id !== productId)
                            )
                        );
                    })
                    .catch((err) => handleError(err))
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [selectedEventId, dispatch, deleteAppointmentProduct, handleError]
    );

    const onDeleteProduct = useCallback(
        (productId: number) => {
            dispatch(
                openConfirmPopup({
                    title: 'Delete Product',
                    text: 'Are you sure you want to remove this product from appointment?',
                    confirmText: 'Delete',
                    onConfirm: () => handleDeleteProduct(productId)
                })
            );
        },
        [dispatch, handleDeleteProduct]
    );

    const renderDialogTitle = useCallback(
        (title: string) => (
            <Stack spacing={1} direction="row" alignItems="center">
                <KeyboardBackspaceOutlinedIcon color="inherit" /> <span>{title}</span>
            </Stack>
        ),
        []
    );

    return (
        <Stack display="flex" height="100%" spacing={2} px={spacingX}>
            {showMainContent && (
                <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <SectionHeading>Products</SectionHeading>
                        <Button
                            color="primary"
                            variant="text"
                            className="forcedBg"
                            size="small"
                            onClick={() => setCreating(true)}
                            disabled={!canAddProduct}
                            sx={{ alignSelf: 'flex-start', flexGrow: 0, flexShrink: 0 }}
                            endIcon={<AddOutlinedIcon />}
                        >
                            Add New
                        </Button>
                    </Stack>
                    <AppointmentProductsTable
                        products={products}
                        isLoading={isLoading}
                        onDelete={onDeleteProduct}
                        onUpdate={onUpdateProduct}
                    />
                </>
            )}
            <Box>
                <PermanentEntityDrawer title={renderDialogTitle('Add Product')} open={creating} onClose={() => setCreating(false)}>
                    <AppointmentProductForm
                        onSubmit={handleCreateProduct}
                        isBusy={isSaving}
                        excludedValues={products.map(({ id }) => id)}
                        onCancel={() => setCreating(false)}
                    />
                </PermanentEntityDrawer>

                <PermanentEntityDrawer title={renderDialogTitle('Edit Product')} open={!!editing} onClose={() => setEditing(null)}>
                    <AppointmentProductForm
                        product={
                            editing
                                ? {
                                      productId: editing.id,
                                      amount: editing.pivot.amount,
                                      price: editing.pivot.price
                                  }
                                : undefined
                        }
                        productData={editing}
                        onSubmit={handleUpdateProduct}
                        isBusy={isUpdating}
                        excludedValues={products.filter(({ id }) => id !== editing?.id).map(({ id }) => id)}
                        onCancel={() => setEditing(null)}
                    />
                </PermanentEntityDrawer>
            </Box>
        </Stack>
    );
};

export default AppointmentProducts;
