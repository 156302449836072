import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Theme, useMediaQuery, useTheme, Tab, Tabs, Button, Box } from '@mui/material';
import { ICompany, ICompanySettings } from '../../models/ICompany';
import { LoadingButton } from '@mui/lab';
import companyAPI from '../../services/CompanyService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';
import TabPanel, { a11yProps } from '../../ui-component/TabPanel';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import salesTaxSchema from '../../ui-component/form/schemes/sales-tax-schema';
import { EmployeeCommissionType } from '../../models/IEmployee';
import { NotificationsTab, AppointmentsTab, PaymentsTab, IntegrationsTab, KioskTab, CheckInTab } from './panels';
import ExtensionsTab from './panels/ExtensionsTab';
import StickyFormFooter from '../../ui-component/form/sticky-form-footer';
import CbPortal from '../../ui-component/cb-portal';

interface SettingsFormProps {
    company: ICompany;
    saveCb: () => void;
}

const CommissionSchema = Yup.object().shape({
    type: Yup.string().trim().oneOf(['fixed', 'percent']).required().label('Commission Type'),
    amount: Yup.number()
        .typeError('Commission Amount is a required field')
        .min(0)
        .when('type', {
            is: 'percent',
            then: Yup.number().typeError('Commission Amount is a required field').min(0).max(100).required()
        })
        .required()
        .label('Commission Amount')
});

const SettingsForm = ({ company, saveCb }: SettingsFormProps) => {
    const { checkAuthentication } = useAuth();
    const [updateCompanySettings, { isLoading }] = companyAPI.useUpdateCompanySettingsMutation();
    const { showSnackbar } = useShowSnackbar();
    const [value, setValue] = useState(0);
    const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('mobile'));
    const isXSmall = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const legacyRepManagement = company.cb_settings?.cb?.use_legacy_reputation_management;

    const initialValues = useMemo(
        () => ({
            ...company.settings,
            sales_tax: {
                enabled: Boolean(company.settings.sales_tax.enabled),
                override_allowed: Boolean(company.settings.sales_tax.override_allowed),
                use_for_services: Boolean(company.settings.sales_tax.use_for_services),
                use_for_products: Boolean(company.settings.sales_tax.use_for_products),
                included_in_price: Boolean(company.settings.sales_tax.included_in_price)
            },
            appointments: {
                ...company.settings.appointments,
                waitlist: {
                    enabled: Boolean(company.settings.appointments.waitlist.enabled),
                    any_employee: Boolean(company.settings.appointments.waitlist.any_employee)
                }
            }
        }),
        [company.settings]
    );

    const loyaltyProgramSet = Boolean(initialValues?.loyalty_program?.type);

    const form = useExtendedFormik<ICompanySettings>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        initialValues,
        validationSchema: Yup.object().shape({
            notifications: Yup.object().shape({
                enabled: Yup.boolean().nullable(),
                immediately_sms_notify: Yup.boolean().nullable(),
                delay: Yup.number().required().min(0).max(60).label('Notifications Delay'),
                customer: Yup.object().shape({
                    appointment_deposit_request_reminder: Yup.number().required().min(1).label('Appointment Deposit Request'),
                    appointment_invite_reminder: Yup.object().shape({
                        first: Yup.number().required().min(1).label('Appointment Request Reminder'),
                        final: Yup.number().required().min(1).label('Appointment Request Final')
                    }),
                    appointment_reminder: Yup.object().shape({
                        virtual_service: Yup.number().required().min(1).label('Virtual Service Reminder'),
                        service: Yup.number().required().min(1).label('Service Reminder')
                    }),
                    rebook_reminder_follow_up: Yup.number().required().min(1).label('Rebook Reminder Follow-up Request')
                })
            }),
            appointments: Yup.object().shape({
                no_show_deposit: Yup.object().shape({
                    enabled: Yup.boolean().required('Required field'),
                    percent: Yup.number().when('enabled', {
                        is: true,
                        then: Yup.number().min(1, 'Minimum value is 1').max(100, 'Maximum value is 100'),
                        otherwise: Yup.number().nullable()
                    })
                }),
                use_contact_privacy: Yup.boolean(),
                use_products: Yup.boolean()
            }),
            integrations: Yup.object().shape({
                reputation_management: legacyRepManagement
                    ? Yup.string().required('Reputation management is required')
                    : Yup.string().nullable().notRequired(),
                gradeus: Yup.object().shape({
                    api_key: Yup.string().nullable(),
                    profile_id: Yup.string().nullable()
                }),
                reviewshake: Yup.object().shape({
                    api_key: Yup.string().nullable(),
                    custom_domain: Yup.string().nullable(),
                    subdomain: Yup.string().nullable(),
                    campaign: Yup.string().nullable(),
                    client: Yup.string().nullable(),
                    location_slug: Yup.string().nullable()
                }),
                paypal: Yup.object()
                    .shape(
                        {
                            client_id: Yup.string()
                                .trim()
                                .nullable()
                                .when('client_secret', {
                                    is: (v?: string | null) => !!v,
                                    then: Yup.string()
                                        .trim()
                                        .nullable()
                                        .required('The PayPal Client ID field is required when PayPal Client Secret is present.')
                                }),
                            client_secret: Yup.string()
                                .trim()
                                .nullable()
                                .when('client_id', {
                                    is: (v?: string | null) => !!v,
                                    then: Yup.string()
                                        .trim()
                                        .nullable()
                                        .required('The PayPal Client Secret  field is required when PayPal Client ID is present.')
                                })
                        },
                        [['client_id', 'client_secret']]
                    )
                    .nullable(),
                cc_processor: Yup.string().trim().nullable().required('CC processor is required'),
                authorize_net: Yup.object().shape({
                    api_login_id: Yup.string().nullable(),
                    transaction_key: Yup.string().nullable()
                }),
                stripe: Yup.object().shape({
                    secret_key: Yup.string().nullable(),
                    publishable_key: Yup.string().nullable()
                }),
                twilio: Yup.object().shape({
                    auth_token: Yup.string().nullable(),
                    account_sid: Yup.string().nullable()
                }),
                meetings: Yup.string().trim().required().oneOf(['whereby', 'zoom']).label('Meetings'),
                zoom: Yup.mixed().when('meetings', {
                    is: 'zoom',
                    then: Yup.object().shape({
                        account_id: Yup.string().trim().nullable().required().label('Zoom Account Id'),
                        client_id: Yup.string().trim().nullable().required().label('Zoom Client Id'),
                        client_secret: Yup.string().trim().nullable().required().label('Zoom Client Secret')
                    }),
                    otherwise: Yup.mixed().nullable().notRequired()
                })
            }),
            sales_tax: salesTaxSchema,
            enable_commissions: Yup.boolean(),
            default_commission: Yup.object()
                .shape({
                    services: CommissionSchema,
                    products: CommissionSchema
                })
                .when('enable_commissions', { is: false, then: Yup.object().nullable().notRequired() }),
            loyalty_program: Yup.object().shape({
                enabled: Yup.boolean()
            })
        }),
        onSubmit: (formData) => {
            updateCompanySettings({ settings: formData, companyId: company.id })
                .unwrap()
                .then(() => {
                    saveCb();
                    showSnackbar({
                        message: 'Settings updated successfully',
                        alertSeverity: SnackBarTypes.Success
                    });
                    checkAuthentication();
                })
                .catch((e) => {
                    if (e.errors) {
                        Object.keys(e.errors).forEach((key) => {
                            const fieldName = key.replace('settings.', '');
                            setFieldError(fieldName, e.errors[key]);
                            // setFieldTouched(fieldName, true);
                        });
                    }
                    showSnackbar({
                        message: e.message || "Settings wasn't updated",
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        }
    });

    const { handleSubmit, values, setFieldValue, touched, errors, setFieldError, resetForm, dirty } = form;

    const formActionsVisible = useMemo(() => dirty, [dirty]);

    const getZoomIntegrationsError = useCallback(
        (field: 'account_id' | 'client_id' | 'client_secret'): string | undefined => {
            const isTouched = typeof touched.integrations?.zoom === 'object' ? Boolean(touched.integrations?.zoom?.[field]) : false;
            const errMessage = typeof errors.integrations?.zoom === 'object' ? errors.integrations?.zoom?.[field] : undefined;

            return isTouched ? errMessage : undefined;
        },
        [touched, errors]
    );

    const hasErrorsInTab = useCallback(
        (tab: 'appointments' | 'payments' | 'integrations' | 'extensions' | 'notifications' | 'check_in') => {
            const { appointments, integrations, default_commission, notifications, loyalty_program } = errors;
            switch (tab) {
                case 'appointments': {
                    return !!appointments;
                }

                case 'integrations': {
                    return (
                        !!integrations?.gradeus ||
                        !!integrations?.reviewshake ||
                        !!integrations?.reputation_management ||
                        !!integrations?.twilio ||
                        !!integrations?.meetings
                    );
                }

                case 'payments': {
                    return (
                        !!integrations?.authorize_net || !!integrations?.paypal || !!integrations?.cc_processor || !!integrations?.stripe
                    );
                }

                case 'extensions': {
                    return (
                        !!loyalty_program ||
                        !!default_commission ||
                        (['account_id', 'client_id', 'client_secret'] as const).some((field) => !!getZoomIntegrationsError(field))
                    );
                }

                case 'notifications': {
                    return !!notifications;
                }

                default: {
                    return false;
                }
            }
        },
        [errors, getZoomIntegrationsError]
    );

    useEffect(() => {
        if (values.enable_commissions && !values.default_commission) {
            setFieldValue('default_commission', {
                services: { type: EmployeeCommissionType.Percent, amount: '' },
                products: { type: EmployeeCommissionType.Percent, amount: '' }
            });
        }

        if (!values.enable_commissions) {
            setFieldValue('default_commission', null);
        }
    }, [setFieldValue, values.default_commission, values.enable_commissions]);

    useEffect(() => {
        if (!values.sales_tax.enabled) {
            setFieldValue('sales_tax.included_in_price', false);
            setFieldValue('sales_tax.use_for_services', false);
            setFieldValue('sales_tax.use_for_products', false);
            setFieldValue('sales_tax.override_allowed', false);
        }
    }, [setFieldValue, values.sales_tax.enabled]);

    return (
        <Box
            component="form"
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
            onSubmit={handleSubmit}
            noValidate
            id="settings-form"
            pb={!isMobile ? 2 : 0}
        >
            <Box sx={{ mr: isXSmall ? -2 : undefined }}>
                <Tabs
                    sx={{
                        mb: 3,
                        '& .MuiTab-root.Mui-error': { color: theme.palette.error.main },
                        '& .MuiTab-root.MuiButtonBase-root': isMobile
                            ? {
                                  p: 1,
                                  minWidth: '50px',
                                  flexGrow: 1
                              }
                            : undefined
                    }}
                    value={value}
                    onChange={handleTabsChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab
                        iconPosition="start"
                        icon={<NotificationsOutlinedIcon />}
                        label="Notifications"
                        className={hasErrorsInTab('notifications') ? 'Mui-error' : ''}
                    />
                    <Tab
                        iconPosition="start"
                        icon={<GroupOutlinedIcon />}
                        label="Appointments"
                        className={hasErrorsInTab('appointments') ? 'Mui-error' : ''}
                    />
                    <Tab
                        iconPosition="start"
                        icon={<PaymentOutlinedIcon />}
                        label="Payments"
                        className={hasErrorsInTab('payments') ? 'Mui-error' : ''}
                    />
                    <Tab
                        iconPosition="start"
                        icon={<CloudDoneOutlinedIcon />}
                        label="Integrations"
                        className={hasErrorsInTab('integrations') ? 'Mui-error' : ''}
                    />
                    <Tab iconPosition="start" icon={<StorefrontOutlinedIcon />} label="Kiosk" />

                    <Tab
                        iconPosition="start"
                        icon={<IntegrationInstructionsOutlinedIcon />}
                        label="Extensions"
                        className={hasErrorsInTab('extensions') ? 'Mui-error' : ''}
                    />

                    <Tab
                        iconPosition="start"
                        icon={<AssignmentTurnedInOutlinedIcon />}
                        label="Check In"
                        className={hasErrorsInTab('check_in') ? 'Mui-error' : ''}
                    />
                </Tabs>
            </Box>

            <Box px={isMobile ? 0 : 3}>
                {/* Notifications tab */}
                <TabPanel value={value} index={0} prefix="settings" {...a11yProps(0, 'settings')}>
                    <NotificationsTab formInstance={form} />
                </TabPanel>

                {/* Appointments tab */}
                <TabPanel value={value} index={1} prefix="settings" {...a11yProps(1, 'settings')}>
                    <AppointmentsTab formInstance={form} />
                </TabPanel>

                {/* Payments Tab */}
                <TabPanel value={value} index={2} prefix="settings" {...a11yProps(2, 'settings')}>
                    <PaymentsTab formInstance={form} />
                </TabPanel>

                {/* Integrations Tab */}
                <TabPanel value={value} index={3} prefix="settings" {...a11yProps(3, 'settings')}>
                    <IntegrationsTab formInstance={form} company={company} />
                </TabPanel>

                {/* Kiosk Tab */}
                <TabPanel value={value} index={4} prefix="settings" {...a11yProps(4, 'settings')}>
                    <KioskTab company={company} />
                </TabPanel>

                {/* Extensions Tab */}
                <TabPanel value={value} index={5} prefix="settings" {...a11yProps(5, 'settings')}>
                    <ExtensionsTab formInstance={form} loyaltyProgramSet={loyaltyProgramSet} />
                </TabPanel>

                {/* Check In Tab */}
                <TabPanel value={value} index={6} prefix="settings" {...a11yProps(6, 'settings')}>
                    <CheckInTab company={company} formInstance={form} />
                </TabPanel>
            </Box>

            <CbPortal query="#external_actions" disableCondition={isMobile}>
                {formActionsVisible ? (
                    <StickyFormFooter containerXSpacing={isMobile ? 2 : undefined}>
                        <Button color="primary" className="forcedBg" disabled={isLoading || !dirty} onClick={() => resetForm()}>
                            Cancel
                        </Button>
                        <LoadingButton
                            disabled={!dirty}
                            loading={isLoading}
                            form="settings-form"
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            Save
                        </LoadingButton>
                    </StickyFormFooter>
                ) : null}
            </CbPortal>
        </Box>
    );
};

export default SettingsForm;
