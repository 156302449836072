const SuccessIcon = () => {
    return (
        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_261_888)">
                <circle cx="32.5" cy="32" r="32" fill="#F0FEF6" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.5 53C44.098 53 53.5 43.598 53.5 32C53.5 20.402 44.098 11 32.5 11C20.902 11 11.5 20.402 11.5 32C11.5 43.598 20.902 53 32.5 53ZM41.7572 26.8839C42.2454 26.3957 42.2454 25.6043 41.7572 25.1161C41.2691 24.628 40.4776 24.628 39.9895 25.1161L28.8734 36.2322L25.0105 32.3694C24.5223 31.8812 23.7309 31.8812 23.2427 32.3694C22.7545 32.8575 22.7545 33.649 23.2427 34.1371L27.9895 38.8839L28.8734 39.7678L29.7572 38.8839L41.7572 26.8839Z"
                    fill="#80DFC5"
                />
                <path
                    d="M16.1398 45.1671C13.2374 41.5654 11.5001 36.9856 11.5001 32C11.5001 20.402 20.9021 11 32.5001 11C44.098 11 53.5001 20.402 53.5001 32C53.5001 43.598 44.098 53 32.5001 53C27.9815 53 23.7962 51.5729 20.3705 49.1448"
                    stroke="#0A3A5B"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_261_888">
                    <rect width="64" height="64" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const WarningIcon = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5830_140476)">
                <circle cx="32" cy="32" r="32" fill="#FEFAF0" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.5484 11.7203L12.4783 47.248C11.8015 48.5786 12.7682 50.1547 14.2609 50.1547H49.7887C51.2694 50.1547 52.2367 48.6016 51.5837 47.2727L34.1261 11.7449C33.3993 10.2657 31.2956 10.2512 30.5484 11.7203ZM31.9999 21.334C31.3095 21.334 30.7499 21.8936 30.7499 22.584V36.084C30.7499 36.7743 31.3095 37.334 31.9999 37.334C32.6902 37.334 33.2499 36.7743 33.2499 36.084V22.584C33.2499 21.8936 32.6902 21.334 31.9999 21.334ZM31.9999 42.6642C32.8283 42.6642 33.4999 41.9926 33.4999 41.1642C33.4999 40.3357 32.8283 39.6642 31.9999 39.6642C31.1714 39.6642 30.4999 40.3357 30.4999 41.1642C30.4999 41.9926 31.1714 42.6642 31.9999 42.6642Z"
                    fill="#F1C40F"
                />
                <path
                    d="M42.0016 50.1547H14.2612C12.7684 50.1547 11.8018 48.5786 12.4785 47.248L30.5487 11.7203C31.2959 10.2512 33.3995 10.2657 34.1264 11.7449L51.5839 47.2727C52.2369 48.6016 51.2697 50.1547 49.7889 50.1547L48.1881 50.1547"
                    stroke="#0A3A5B"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5830_140476">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { SuccessIcon, WarningIcon };
