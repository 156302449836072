import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';

import CustomerDetails from './components/CustomerDetails';
import EntityDrawerContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import UpdatedStyleWrapper from '../../../ui-component/updated-style-wrapper';
import CustomerNotes from '../customer-notes';
import CustomerMessages from './components/CustomerMessages';
import useMessagingEnabled from '../../../hooks/use-messaging-enabled';
import { useSearchParams } from 'react-router-dom';

interface CustomerInfoModalProps {
    customerId: string;
    onClose: () => void;
}

const CustomerInfoDialog = ({ customerId, onClose }: CustomerInfoModalProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isMessagingEnabled = useMessagingEnabled();

    const [value, setValue] = useState(0);
    const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab === 'messages') {
            isMessagingEnabled && setValue(2);
            setSearchParams(new URLSearchParams());
        }
    }, [isMessagingEnabled, searchParams, setSearchParams]);

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Customer Info" onClose={onClose} />
                <Tabs value={value} onChange={handleTabsChange}>
                    <Tab label="Details" />
                    <Tab label="Notes" />
                    {isMessagingEnabled && <Tab label="Messages" />}
                </Tabs>

                {value === 0 && <CustomerDetails customerId={customerId} onClose={onClose} />}
                {value === 1 && <CustomerNotes customerId={customerId} />}
                {value === 2 && isMessagingEnabled && <CustomerMessages customerId={customerId} />}
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default CustomerInfoDialog;
