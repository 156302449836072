import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

// MUI
import { Box, Chip, ClickAwayListener, Grid, IconButton, InputAdornment, Popper, Stack, TextField } from '@mui/material';
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';

import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

// Third Party
import { useDropzone } from 'react-dropzone';
import Picker, { IEmojiData, SKIN_TONE_NEUTRAL } from 'emoji-picker-react';

// Project
import MainCard from '../cards/MainCard';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import customerAPI from '../../services/CustomerService';
import { useAppSelector } from '../../hooks/redux';
import useMessagesCache from '../../hooks/use-messages-cache';

const ChatInput: FC<{ conversationId?: number; customerId: number }> = ({ conversationId, customerId }) => {
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [send] = customerAPI.useSendCommentMutation();
    const [createConversation] = customerAPI.useCreateNewConversationMutation();
    const { selectedEvent } = useAppSelector((store) => store.calendar);

    const { showSnackbar } = useShowSnackbar();
    const { updateMessagesCache } = useMessagesCache();

    const onEmojiClick = (_event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
        setMessage(message + emojiObject.emoji);
    };

    const [anchorElEmoji, setAnchorElEmoji] = React.useState<any>();
    /** No single type can cater for all elements */
    const handleOnEmojiButtonClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
    };

    const emojiOpen = Boolean(anchorElEmoji);
    const emojiId = emojiOpen ? 'simple-popper' : undefined;
    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
    };

    const videoCallLink = useMemo(() => {
        if (selectedEvent?.cbvc_url) {
            return selectedEvent.cbvc_url;
        }

        return null;
    }, [selectedEvent]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const copyVideoCallLink = useCallback(() => {
        if (videoCallLink) {
            setMessage((prevState) => {
                const beforeCursor = prevState.slice(0, inputRef.current?.selectionStart ?? 0).trim();
                const afterCursor = prevState.slice(inputRef.current?.selectionEnd ?? 0).trim();
                return `${beforeCursor} ${videoCallLink} ${afterCursor}`.trim();
            });
        }
    }, [videoCallLink]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        noDrag: true,
        disabled: loading,
        onDrop: (acceptedFiles, fileRejections) => {
            setAttachment(acceptedFiles[0]);
            if (fileRejections.length) {
                const [error] = fileRejections;
                showSnackbar({
                    alertSeverity: SnackBarTypes.Error,
                    message: error.errors.map((err) => err.message).join(', ')
                });
            }
        },
        maxSize: 1000 * 1024 //  1mb
    });

    const canSendMessage = useMemo(() => !loading && (!!message.trim() || attachment), [attachment, loading, message]);

    const handleSendMessage = useCallback(async () => {
        if (customerId) {
            setLoading(true);
            const payload = {
                customerId,
                text: message.trim(),
                attachment
            };

            (conversationId ? send({ ...payload, conversationId }) : createConversation(payload))
                .unwrap()
                .then((res) => {
                    setMessage('');

                    setAttachment(undefined);
                    updateMessagesCache(customerId, res);
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.message ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [customerId, attachment, conversationId, createConversation, message, send, showSnackbar, updateMessagesCache]);

    return (
        <Box sx={{ px: 3, py: 1, mb: -2, borderTop: '1px solid', borderColor: 'grey.200' }}>
            <Stack spacing={1} alignItems="center" direction="row">
                <Stack
                    direction="row"
                    sx={{
                        color: 'newColors.darkBlue',
                        flexGrow: 0,
                        flexShrink: 0
                    }}
                >
                    {videoCallLink && (
                        <Grid item sx={{ color: 'newColors.darkBlue' }}>
                            <IconButton onClick={copyVideoCallLink} disabled={loading} color="inherit">
                                <VideocamOutlinedIcon />
                            </IconButton>
                        </Grid>
                    )}

                    <IconButton
                        ref={anchorElEmoji}
                        aria-describedby={emojiId}
                        onClick={handleOnEmojiButtonClick}
                        disabled={loading}
                        color="inherit"
                    >
                        <AddReactionOutlinedIcon />
                    </IconButton>
                    <Popper
                        id={emojiId}
                        open={emojiOpen}
                        anchorEl={anchorElEmoji}
                        disablePortal
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [-20, 20]
                                    }
                                }
                            ]
                        }}
                    >
                        <ClickAwayListener onClickAway={handleCloseEmoji}>
                            <MainCard elevation={8} content={false}>
                                <Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_NEUTRAL} disableAutoFocus />
                            </MainCard>
                        </ClickAwayListener>
                    </Popper>
                </Stack>

                <TextField
                    variant="outlined"
                    multiline
                    minRows={1}
                    maxRows={4}
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    inputRef={inputRef}
                    disabled={loading}
                    sx={{
                        flexGrow: 1,
                        flexShrink: 1,
                        '& .MuiInputBase-root': {
                            minHeight: '4rem'
                        },

                        '& .Mui-focused': {
                            backgroundColor: 'primary.light',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'newColors.darkBlue'
                            }
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ px: 1.5, color: 'newColors.darkBlue' }}>
                                {attachment ? (
                                    <Chip
                                        sx={{ maxWidth: '120px' }}
                                        label={attachment.name}
                                        size="small"
                                        onDelete={loading ? undefined : () => setAttachment(undefined)}
                                    />
                                ) : (
                                    <Box {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <IconButton disabled={loading} color="inherit">
                                            <AttachmentTwoToneIcon />
                                        </IconButton>
                                    </Box>
                                )}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end" sx={{ px: 1.5, color: 'newColors.darkBlue' }}>
                                <IconButton onClick={handleSendMessage} disabled={!canSendMessage} color="inherit">
                                    <SendTwoToneIcon />
                                </IconButton>
                            </InputAdornment>
                        ),

                        onKeyDown: (e) => {
                            if (canSendMessage && e.code === 'Enter' && !e.shiftKey) {
                                e.stopPropagation();
                                handleSendMessage();
                            }
                        }
                    }}
                />
            </Stack>
        </Box>
    );
};

export default ChatInput;
