import { Box, MenuItem, Stack, TextField, TextFieldProps } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { FC, useCallback } from 'react';

type HeaderSelectOption = {
    id: number;
    label: string;
    secondaryText?: string;
};

type HeaderSelectProps = TextFieldProps & { options: HeaderSelectOption[] };
const HeaderSelect: FC<HeaderSelectProps> = ({ options, value, label, children, ...rest }) => {
    const getOptionLabel = useCallback((id: unknown) => options.find((opt) => opt.id === id)?.label ?? '', [options]);

    return (
        <TextField
            {...rest}
            select
            variant="outlined"
            value={value}
            SelectProps={{
                renderValue: (v) => (
                    <Box sx={{ maxWidth: '200px' }}>
                        {label && (
                            <Box
                                sx={{
                                    color: 'grey.300',
                                    textTransform: 'uppercase',
                                    whitespace: 'no-wrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: '10px',
                                    lineHeight: '14px'
                                }}
                            >
                                {label}
                            </Box>
                        )}
                        <Box
                            sx={{
                                width: '100%',
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                color: 'grey.800',
                                fontWeight: 600,
                                whitespace: 'no-wrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {getOptionLabel(v)}
                        </Box>
                    </Box>
                )
            }}
            sx={{
                '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'newColors.darkBlue'
                },

                '& .MuiSelect-icon': {
                    display: 'none'
                },

                '& .MuiInputAdornment-root': {
                    color: 'grey.800',
                    marginRight: '4px'
                },

                '& .MuiInputBase-root': {
                    paddingLeft: '8px !important'
                },

                '& .MuiSelect-select': {
                    paddingRight: '8px !important',
                    py: '7px'
                }
            }}
        >
            {options.map((option) => (
                <MenuItem key={option.id} value={option.id} sx={{ px: 1.5, py: 2, fontSize: '1rem', color: 'grey.800', lineHeight: 1.5 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {option.id === value ? (
                            <RadioButtonCheckedIcon color="inherit" />
                        ) : (
                            <RadioButtonUncheckedIcon sx={{ color: 'newColors.darkBlue' }} />
                        )}

                        <Box sx={{ maxWidth: '320px' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontWeight: 400,
                                    fontSize: '1rem',
                                    lineHeight: '1.5rem',
                                    color: 'grey.800'
                                }}
                            >
                                {option.label}
                            </Box>
                            {option.secondaryText && (
                                <Box
                                    sx={{
                                        pt: 0.5,
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontWeight: 400,
                                        fontSize: '0.875rem',
                                        lineHeight: '1.25rem',
                                        color: 'newColors.darkBlue'
                                    }}
                                >
                                    {option.secondaryText}
                                </Box>
                            )}
                        </Box>
                    </Stack>
                </MenuItem>
            ))}
            {children}
        </TextField>
    );
};

export default HeaderSelect;
