import { Box, Button, Stack, Typography } from '@mui/material';
import CustomPhoneInput from '../form/CustomPhoneInput';
import { useFormik } from 'formik';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../store/snackbarReducer';
import * as Yup from 'yup';
import { isValidPhone } from '../../utils/phone-helpers';
import { useAppDispatch } from '../../hooks/redux';
import appointmentAPI from '../../services/AppointmentService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { FC, useCallback } from 'react';

const SelfCheckoutForm: FC<{
    phone?: string;
    appointmentId: number;
    open: boolean;
    onClose: () => void;
}> = ({ phone = '', appointmentId, open, onClose }) => {
    const dispatch = useAppDispatch();
    const [send] = appointmentAPI.useSendSelfCheckoutInviteMutation();
    const { showSnackbar } = useShowSnackbar();

    const { handleSubmit, setFieldValue, values, errors, resetForm } = useFormik<{ phone: string }>({
        initialValues: { phone },
        enableReinitialize: true,
        validateOnChange: true,
        onSubmit: (data) => {
            dispatch(startSubmitting());
            send({ phone: data.phone, appointment_id: appointmentId })
                .unwrap()
                .then(() => {
                    showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Self Checkout link sent' });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.message ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    handleClose();
                    dispatch(stopSubmitting());
                });
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string()
                .trim()
                .required('Phone number is required')
                .test({ name: 'phone', message: 'Must be a valid phone number', test: (v) => isValidPhone(v ?? '') })
        })
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [resetForm, onClose]);

    return (
        <Stack spacing={2} component="form" id="selfCheckoutForm" onSubmit={handleSubmit}>
            <Box>
                <Typography sx={{ fontSize: '1rem', lineHeight: 1.5, color: 'grey.800' }}>Confirm customer&apos;s phone number</Typography>
                <Typography sx={{ fontSize: '0.875rem', color: 'grey.800', opacity: 0.6 }}>
                    A payment request will be sent to the following phone number:
                </Typography>
            </Box>
            <CustomPhoneInput
                label="Phone"
                value={values.phone}
                onChange={(v) => setFieldValue('phone', v)}
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone}
            />
            <Button type="submit" form="selfCheckoutForm" variant="contained" color="primary" sx={{ alignSelf: 'flex-end' }}>
                Send Request
            </Button>
        </Stack>
    );
};

export default SelfCheckoutForm;
