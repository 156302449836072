import { useCallback } from 'react';
import { useAppDispatch } from '../../../hooks/redux';
import ResendIcon from '../../../assets/images/icons/resend.svg';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { axiosServices } from '../../../utils/axios';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import SquareStyledButton from './SquareStyledBtn';

interface ResendConfirmationButtonProps {
    appointmentId?: number;
    disabled?: boolean;
}

const ResendConfirmationButton = ({ appointmentId, disabled }: ResendConfirmationButtonProps) => {
    const dispatch = useAppDispatch();

    const { showSnackbar } = useShowSnackbar();

    const resendConfirmation = useCallback(() => {
        axiosServices
            .post(`appointments/${appointmentId}/resend-confirmation`)
            .then(() => {
                showSnackbar({
                    message: 'Confirmation email sent successfully.',
                    alertSeverity: SnackBarTypes.Success
                });
            })
            .catch((err) => {
                showSnackbar({
                    message: err.message || 'Error occurred, confirmation email was not sent.',
                    alertSeverity: SnackBarTypes.Error
                });
            });
    }, [appointmentId, showSnackbar]);

    const handleClick = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: resendConfirmation,
                confirmText: `Resend`,
                text: `Are you sure you want to resend confirmation?`
            })
        );
    }, [dispatch, resendConfirmation]);

    return (
        <>
            {appointmentId && (
                <SquareStyledButton
                    onClick={handleClick}
                    startIcon={<img src={ResendIcon} alt="Resend Confirmation" />}
                    variant="outlined"
                    color="primary"
                    disabled={disabled}
                />
            )}
        </>
    );
};

export default ResendConfirmationButton;
