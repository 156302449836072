import { Fragment } from 'react';
import { ListItemButton, ListItemText, Stack } from '@mui/material';
import HeaderNavGroup from './components/HeaderNavGroup';
import { Link } from 'react-router-dom';
import commonNavBtnSx from './components/common-nav-btn-sx';
import HeaderNavItemIcon from './components/HeaderNavItemIcon';
import useAvailableMenuItems from '../../menu-items/use-available-menu-items';

const HeaderNavigation = () => {
    const { filteredItems } = useAvailableMenuItems();
    return (
        <Stack
            direction="row"
            spacing={2}
            className="HeaderMenu"
            sx={{
                '& .MuiButtonBase-root.MuiListItemButton-root': {
                    '&:hover, &.Mui-selected': {
                        color: 'primary.900',
                        backgroundColor: 'primary.light'
                    }
                }
            }}
        >
            {filteredItems.map((item) => (
                <Fragment key={item.id}>
                    {item.type === 'group' && !!item.children ? (
                        <HeaderNavGroup item={item} />
                    ) : (
                        <ListItemButton key={item.id} component={Link} to={item.url ?? ''} sx={commonNavBtnSx}>
                            <HeaderNavItemIcon Icon={item.icon ?? undefined} />
                            <ListItemText>{item.title}</ListItemText>
                        </ListItemButton>
                    )}
                </Fragment>
            ))}
        </Stack>
    );
};

export default HeaderNavigation;
