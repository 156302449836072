import { FC, useCallback, useEffect, useMemo } from 'react';
import moment, { Moment } from 'moment-timezone';
import useLocationOptions from '../../hooks/options/useLocationOptions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setCalendarLocationFilter } from '../../store/slices/calendarFilterSlice';
import HeaderSelect from '../../ui-component/header-select';
import { InputAdornment } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import useLocalCalendarFilters from '../../views/new-calendar/hooks/use-local-calendar-filters';

const LocationSwitcher: FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
    const dispatch = useAppDispatch();
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);

    const { locations, isFetching, isLoading } = useLocationOptions();
    const { calendarSettings, updateCalendarSettings } = useLocalCalendarFilters();
    const options = useMemo(
        () =>
            locations.map((location) => {
                const tz: Moment = moment.tz(location.time_zone);
                const offset = tz.format('Z');
                const tz_abbr = tz.format('z');
                const tzLabel = tz_abbr === 'UTC' ? '(UTC)' : `(${tz_abbr}/ UTC${offset})`;
                return {
                    id: location.id,
                    label: location.name,
                    secondaryText: tzLabel
                };
            }),
        [locations]
    );

    const handleChange = useCallback(
        (id: number) => {
            const loc = locations.find((location) => location.id === id);
            if (loc && calendarSettings) {
                dispatch(setCalendarLocationFilter(loc));
                updateCalendarSettings({ ...calendarSettings, selected_location_id: loc.id });
            }
        },
        [locations, dispatch, updateCalendarSettings, calendarSettings]
    );

    useEffect(() => {
        if (!selectedLocation && locations.length && calendarSettings && !isLoading && !isFetching) {
            const defaultLocation = locations.find((l) => l.id === calendarSettings.selected_location_id) ?? locations[0];
            dispatch(setCalendarLocationFilter(defaultLocation));
        }
    }, [dispatch, selectedLocation, locations, calendarSettings, isLoading, isFetching]);

    if (!selectedLocation || locations.length < 2) {
        return null;
    }

    return (
        <HeaderSelect
            label="Location"
            value={selectedLocation?.id ?? null}
            onChange={(e) => handleChange(parseInt(e.target.value, 10))}
            options={options}
            disabled={locations.length < 2 || isLoading || isFetching}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PlaceOutlinedIcon />
                    </InputAdornment>
                )
            }}
            fullWidth={fullWidth}
        />
    );
};

export default LocationSwitcher;
