import { Tabs, Tab, Box } from '@mui/material';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { IAppointment } from '../../../../../models/IAppointment';
import WaiverSignIndicator from '../../../../WaiverSignIndicator';
import AppointmentDepositOpenedIndicator from '../../../../AppointmentDepositOpenedIndicator';
import useAuth from '../../../../../hooks/useAuth';
import useEntityDrawerSpacing from '../../../../entity-drawer-layout/use-entity-drawer-spacing';

interface MenuProps {
    activeTab: string;
    handleClickTab: (val: string) => void;
    has_waiver?: IAppointment['has_waiver'];
    deposit_request?: IAppointment['deposit_request'];
    disabled?: boolean;
}

const Menu = ({ activeTab, handleClickTab, has_waiver, deposit_request, disabled }: MenuProps) => {
    const { isMobile } = useEntityDrawerSpacing();
    const { user } = useAuth();
    const productsDisabled = !user?.currentCompany.settings?.appointments.use_products;

    const handleChange = (_: unknown, newValue: string) => {
        handleClickTab(newValue);
    };

    return (
        <Box
            sx={{
                width: '100%',
                px: isMobile ? 0 : 3,
                '& .MuiTab-root': { minWidth: '73px', minHeight: '49px', py: 1, px: 2, flexGrow: 1 },
                '& .MuiTabs-root': {
                    minHeight: '50px'
                }
            }}
        >
            <Tabs variant="scrollable" scrollButtons="auto" orientation="horizontal" onChange={handleChange} value={activeTab}>
                <Tab value="details" icon={<AssignmentOutlinedIcon />} iconPosition="start" disabled={disabled} />
                <Tab
                    value="customer-info"
                    icon={
                        <>
                            <AssignmentIndOutlinedIcon />
                            <WaiverSignIndicator waiver={has_waiver} />
                        </>
                    }
                    iconPosition="start"
                    disabled={disabled}
                />
                <Tab value="activity" icon={<UpdateOutlinedIcon />} iconPosition="start" disabled={disabled} />
                <Tab value="messages" icon={<ChatBubbleOutlineOutlinedIcon />} iconPosition="start" disabled={disabled} />
                {!productsDisabled && <Tab value="products" icon={<ShoppingBagOutlinedIcon />} iconPosition="start" disabled={disabled} />}
                <Tab
                    value="payments"
                    icon={
                        <>
                            <PaymentOutlined />
                            <AppointmentDepositOpenedIndicator depositRequest={deposit_request} />
                        </>
                    }
                    iconPosition="start"
                    disabled={disabled}
                />
            </Tabs>
        </Box>
    );
};

export default Menu;
