// import { useState } from 'react';

// material-ui
import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MenuIcon from '@mui/icons-material/Menu';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import WidgetLink from './WidgetLink';

// assets
import SupportWidget from './SupportWidget';
import MobileCreateButton from './MobileCreateButton';
import CopyVideoLink from '../../../ui-component/copy-video-link/CopyVideoLink';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import HeaderNavigation from '../../../ui-component/header-navigation';
import useNavigationMode from '../../use-navigation-mode';
import OrganizationSwitcher from '../OrganizationSwitcher';
import NotificationSection from './NotificationSection';
import useAuth from '../../../hooks/useAuth';
import CommonHeaderBtn from '../../../ui-component/CommonHeaderBtn';
import useCashLedger from '../../../hooks/use-cash-ledger';
import { DialogTypes, openDialog } from '../../../store/slices/entityDialogsSlice';
import { useCallback } from 'react';
import UpdatedStyleWrapper from '../../../ui-component/updated-style-wrapper';
import HeaderSearch from './HeaderSearch';
import LocationSwitcher from '../LocationSwitcher';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
    matchSm: boolean;
}

const Header = ({ handleLeftDrawerToggle, matchSm }: HeaderProps) => {
    const isMobile = useMediaQuery((themeParams: Theme) => themeParams.breakpoints.down('mobile'));
    const theme = useTheme();
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { isLedgerVisible } = useCashLedger();
    const navMode = useNavigationMode();
    const bottomMenuVisible = useMediaQuery(theme.breakpoints.down('mobile'));
    const { showSearch } = useAppSelector((state) => state.calendarFilter);

    const onOpenLedger = useCallback(() => {
        dispatch(openDialog({ type: DialogTypes.Ledger, id: null }));
    }, [dispatch]);

    return (
        <UpdatedStyleWrapper>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <Stack spacing={isMobile ? 2 : 4} alignItems="center" direction="row">
                    {/* logo & toggler button */}
                    {navMode !== 'sidebar' ? (
                        <Box component="span" sx={{ display: 'block', flexGrow: 1 }}>
                            <LogoSection />
                        </Box>
                    ) : null}

                    {navMode === 'sidebar' ? (
                        <CommonHeaderBtn onClick={handleLeftDrawerToggle}>
                            <MenuIcon />
                        </CommonHeaderBtn>
                    ) : null}

                    {navMode === 'header' ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexGrow: 0,
                                justifyContent: 'center'
                            }}
                        >
                            <HeaderNavigation />
                        </Box>
                    ) : null}

                    <Box sx={{ '&:empty': { display: 'none' } }} id="calendarTitle" />
                </Stack>

                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                    <Box id="calendarFilterButton" sx={{ '&:empty': { display: 'none' } }} />
                    <Box id="calendarModeSelector" sx={{ '&:empty': { display: 'none' } }} />
                    <Box id="calendarAddButton" sx={{ '&:empty': { display: 'none' } }} />

                    <MobileCreateButton />
                    <SupportWidget />
                    {navMode === 'header' ? (
                        <>
                            <WidgetLink />
                            <CopyVideoLink />
                        </>
                    ) : null}
                    {isLedgerVisible ? (
                        <CommonHeaderBtn onClick={onOpenLedger}>
                            <CurrencyExchangeIcon />
                        </CommonHeaderBtn>
                    ) : null}

                    {user && !bottomMenuVisible ? <NotificationSection /> : null}
                    {showSearch && !bottomMenuVisible ? <HeaderSearch /> : null}

                    {navMode === 'header' ? (
                        <>
                            <LocationSwitcher />
                            {user && !matchSm ? <OrganizationSwitcher /> : null}
                            <ProfileSection />
                        </>
                    ) : null}
                </Stack>
            </Box>
        </UpdatedStyleWrapper>
    );
};

export default Header;
