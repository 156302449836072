import { IEmployee } from './IEmployee';
import { ICustomer } from './ICustomer';
import { IService, PaymentType, ServiceOption, ServiceOptionPivot } from './IService';
import { ILocation } from './ILocation';
import { ImageData } from './IImage';
import { CancellationReason } from '../views/calendar/types';
import { IUser } from './IUser';
import { IPayment } from './IPayment';
import { IWidgetCompany } from './ICompany';
import { IAddress } from './IAddress';

export enum AppointmentStatuses {
    // Created = 'created',
    Active = 'active',
    Completed = 'completed',
    Canceled = 'canceled',
    Pending = 'pending'
}

export enum AppointmentType {
    Appointment = 'appointment',
    Blocked_Time = 'blocked_time',
    BlockedInOtherCompany = 'blocked_in_other_company',
    OwnAppointmentInOtherCompany = 'own_appointmnent_in_other_company',
    OwnBlockInOtherCompany = 'own_block_in_other_company',
    OwnAppointmentInOtherLocation = 'own_appointment_in_other_location',
    ICalEvent = 'ical_event',
    GoogleCalendarEvent = 'google_calendar_event',
    RecurringEvent = 'recurring_event'
}

export interface IAppointmentDepositRequest {
    expires_at: string;
    amount: number;
    reminder: {
        unit: string;
        value: number;
    };
    paid_at?: string | null;
    opened_at?: string | null;
    url?: string;
}

export interface IAppointmentBalanceDetails {
    balance_remaining?: number;
    deposit?: number;
    deposit_paid?: number;
    total_amount?: number;
    total_gratuity?: number;
    total_paid?: number;
    coupon_amount?: number | null;
    coupon_code?: string | null;
    sales_tax: {
        enabled: boolean;
        included_in_price?: boolean;
        amount?: number;
    };
}

export type AppointmentWaiverType =
    | false
    | {
          service_id: number;
          url: string;
          waiver_name: string;
          waiver_signed_at: string;
      }[];

export interface ICalendarAppointment {
    id: number;
    type: AppointmentType;
    status: AppointmentStatuses;
    start_at: string;
    end_at: string;
    employee: {
        id: number;
        user: {
            firstname: string;
            lastname: string;
        };
        title?: string | null;
        background_color?: string | null;
    };
    customer: Pick<ICustomer, 'id' | 'firstname' | 'lastname'>;
    services: Pick<IService, 'id' | 'name' | 'images'>[];
    location: Pick<ILocation, 'id' | 'name'>;
    has_waiver?: AppointmentWaiverType;
    images: ImageData[];
}

export interface IGlobalAppointment {
    id: number;
    name?: string | null;
    type: AppointmentType;
    start_at: string;
    end_at: string;
    employee: {
        id: number;
        user: {
            firstname: string;
            lastname: string;
        };
        title?: string | null;
    };
    customer: Pick<ICustomer, 'id' | 'firstname' | 'lastname'>;
    foreign_employee: Pick<IEmployee, 'id'>;
    services: Pick<IService, 'id' | 'name'>[];
}

export interface IAppointment {
    id: number;
    uuid: string;
    employee: IEmployee;
    services: IService[];
    location: ILocation;
    customer: ICustomer;
    status: AppointmentStatuses;
    start_at: string;
    end_at: string;
    payment_method: string;
    type: AppointmentType;
    note?: string;
    private_note?: string;
    images: ImageData[];
    cancel_reason?: CancellationReason;
    is_in_rescheduling_or_canceling_interval: boolean;
    cbvc_url?: string;
    payments: IPayment[] | null;
    company?: IWidgetCompany;
    payment_type: PaymentType;
    foreign_employee?: IEmployee;
    time_zone?: string | null;
    is_notifications_enabled?: boolean;
    created_at?: string;
    deposit_request?: IAppointmentDepositRequest | null;
    remind_after_interval?: string | null;
    remind_at?: string | null;
    is_self_checkout_available?: boolean;
    balance_details?: IAppointmentBalanceDetails | null;
    has_waiver?: AppointmentWaiverType | 'not_signed';
    signed_waiver?: AppointmentWaiverType;
    address?: IAddress | null;
    foreign_company?: {
        id: string;
        name: string;
    };
    self_checkout_url?: string | null;
    waitlist?: {
        id: number;
        any_employee?: boolean;
    };
}

export interface IUpcomingAppointment {
    id: string;
    status: AppointmentStatuses;
    start_at: string;
    end_at: string;
    type: AppointmentType;
    has_waiver?: AppointmentWaiverType;
}

export interface IDepositRequestPayload {
    expires_at: string;
    amount: number;
    reminder: {
        unit: string;
        value: number;
    };
}

export interface IAppointmentPayload {
    employee_id: IEmployee['id'];
    location_id: ILocation['id'];
    service_ids: { id: number; price?: number | null; prepay?: number | null; options: { id: number | string }[] }[];
    customer_id: ICustomer['id'];
    start_at: IAppointment['start_at'];
    end_at: IAppointment['end_at'];
    type: AppointmentType;
    note?: string;
    private_note?: string;
    images: string[];
    payments: IPayment[] | null;
    is_notifications_enabled?: boolean;
    deposit_request?: IDepositRequestPayload | null;
    address?: IAddress | null;
    remind_after_interval?: string | null;
    remind_at?: string | null;
}

export interface AppointmentStatusPayload {
    status: AppointmentStatuses;
    cancel_reason?: CancellationReason;
    remind_after_interval?: string;
}

export interface WidgetAppointmentPayload {
    employee_id: IEmployee['id'];
    location_id: ILocation['id'];
    service_ids: IService['id'][];
    start_at: IAppointment['start_at'];
    end_at: IAppointment['end_at'];
}

type IAppointmentHistoryProps = Omit<IAppointmentPayload, 'images'>;

export interface IAppointmentHistoryValues extends IAppointmentHistoryProps {
    company_id: string;
    uuid: IAppointment['uuid'];
    id: IAppointment['id'];
    status: AppointmentStatuses;
}

export enum AppointmentHistoryEvent {
    Created = 'created',
    Updated = 'updated',
    ServiceCreated = 'service pivot created',
    ServiceUpdated = 'service pivot updated',
    ServiceDeleted = 'service pivot deleted',
    ServiceOptionCreated = 'service option pivot created',
    ServiceOptionUpdated = 'service option pivot updated',
    ServiceOptionDeleted = 'service option pivot deleted',
    ProductCreated = 'product pivot created',
    ProductUpdated = 'product pivot updated',
    ProductDeleted = 'product pivot deleted',
    CustomerPivotCreated = 'customer pivot created',
    CustomerPivotUpdated = 'customer pivot updated'
}

export interface IAppointmentHistory {
    event: AppointmentHistoryEvent;
    old_values: IAppointmentHistoryValues;
    new_values: IAppointmentHistoryValues;
    modified_values: Record<string, { new: unknown; old: unknown }>;
    created_at: string;
    user: IUser | null;
    event_source: 'backoffice' | 'widget';
}

export type AppointmentInviteType = Pick<IAppointment, 'employee' | 'services' | 'location' | 'customer'> & {
    appointment_invite_uuid: string;
    duration: number;
};

export interface IAppointmentInvitePayload {
    employee_id: number;
    location_id: number;
    service_ids: { id: number; price?: number | null; prepay?: number | null }[];
    customer_id: number;
    note?: string | null;
    private_note?: string | null;
    images: string[] | null;
    duration: number;
    is_notifications_enabled: boolean;
}

export interface IConversation {
    id: number;
    preview: string;
    subject: string;
    customer: {
        id: number;
        firstname: string;
        lastname?: string;
    };
    customer_waiting_since: {
        time: string;
    };
}

export interface IThreadAttachment {
    file_name: string;
    file_url: string;
    mime_type: string;
    size: number;
}

export interface IConversationThread {
    id: number;
    body: string;
    created_at: string;
    created_by?: {
        type: string;
    } | null;
    created_by_employee_id: number | null;
    type: string;
    attachments?: IThreadAttachment[];
}

export interface IConversationDetails extends IConversation {
    threads: IConversationThread[];
}

export type AppointmentServiceRowType = {
    service?: IService | null;
    price?: number | null;
    prepay?: number | null;
    materials_amount: number | null;
    options: Array<ServiceOption & { pivot: ServiceOptionPivot }>;
};

export type AppointmentFormType = Pick<
    IAppointment,
    | 'status'
    | 'type'
    | 'note'
    | 'private_note'
    | 'images'
    | 'payments'
    | 'is_notifications_enabled'
    | 'cancel_reason'
    | 'remind_after_interval'
    | 'remind_at'
    | 'has_waiver'
    | 'cbvc_url'
    | 'address'
> & {
    id?: number;
    employee: IEmployee | null;
    location: ILocation | null;
    customer: ICustomer | null;
    start_at: IAppointment['start_at'] | null;
    end_at: IAppointment['end_at'] | null;
    deposit_request?: IDepositRequestPayload | null;
    services: AppointmentServiceRowType[];
    use_reminder: boolean;
    duration: number;
};
