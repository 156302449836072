import { FC, useCallback, useMemo } from 'react';
import { Box, IconButton, Stack, Theme, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { INotification } from '../../../../models/INotification';
import moment from 'moment';
import useNotificationItem from '../../../../hooks/useNotificationItem';
import ErrorCatcher from '../../../../ui-component/error-catcher';

const animatedSx = {
    transformOrigin: 'right center',

    '@keyframes appear': {
        '0%': { transform: 'scaleX(0)' },
        '63%': { transform: 'scaleX(1)' },
        '81%': { transform: 'scale(1.1)' },
        '100%': { transform: 'scale(1)' }
    },

    animation: 'appear 480ms forwards ease-in-out'
};

const NotificationPreview: FC<{ notification: INotification; onDismiss?: () => void }> = ({ notification, onDismiss }) => {
    const matchDownMd = useMediaQuery((themeParams: Theme) => themeParams.breakpoints.down('md'));
    const minHeight = matchDownMd ? '36px' : '44px';

    const sx = useMemo<SxProps>(
        () => ({
            cursor: 'pointer',
            userSelect: 'none',
            minHeight,
            alignItems: 'center',
            px: 1,
            height: '100%',
            borderRadius: '12px',
            ...animatedSx
        }),
        [minHeight]
    );

    const { customerName, notificationData, isMessage, handleClickNotification } = useNotificationItem(notification);

    const handleClick = useCallback(() => {
        handleClickNotification().finally(() => {
            onDismiss?.();
        });
    }, [handleClickNotification, onDismiss]);

    return (
        <ErrorCatcher wrappedComponentName="Notification Preview">
            <Stack
                onClick={handleClick}
                direction="row"
                spacing={1}
                sx={{
                    ...sx,
                    backgroundColor: 'primary.light',
                    color: 'primary.900'
                }}
            >
                {notificationData.icon}
                <Box sx={{ fontWeight: 700 }}>{notificationData.title}</Box>
                <Box>{customerName}</Box>
                {!isMessage ? <Box>{moment(notification.data.start_at).format('MM/DD/YY')}</Box> : null}
                {onDismiss ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexGrow: 1
                        }}
                    >
                        <IconButton
                            color="inherit"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDismiss();
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                ) : null}
            </Stack>
        </ErrorCatcher>
    );
};

export default NotificationPreview;
