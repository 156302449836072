import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { IconCurrencyDollar } from '@tabler/icons';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

// material-ui
import {
    Avatar,
    Divider,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Typography,
    useTheme,
    Box,
    Stack,
    IconButton
} from '@mui/material';
import ManageAccounts from '@mui/icons-material/ManageAccounts';

// project imports
import useAuth from 'hooks/useAuth';
import preferredImageSize from '../../../../utils/preferred-image-size';

// assets
import { colors } from '../../../../store/constant';
import EmployeeRoleChip from '../../../../ui-component/EmployeeRoleChip';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedIndex] = React.useState(1);
    const { logout, user } = useAuth();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
        location.state = null;
    };

    const handleOpenSettings = () => {
        navigate('/profile');
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Avatar
                src={preferredImageSize(user.avatar, 'small')}
                aria-controls={anchorEl ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                sx={{
                    backgroundColor: user.employee.background_color ? `#${user.employee.background_color}` : colors.blue.value,
                    cursor: 'pointer',
                    width: '40px',
                    height: '40px',
                    fontSize: '1.2rem',
                    '&:hover': {
                        boxShadow: `0 0 0 5px 1px ${
                            user.employee.background_color ? `#${user.employee.background_color}` : colors.blue.value
                        }`
                    }
                }}
                onClick={openMenu}
            >
                <Typography
                    fontSize="inherit"
                    sx={{
                        color: theme.palette.getContrastText(
                            user.employee.background_color ? `#${user.employee.background_color}` : colors.blue.value
                        )
                    }}
                >
                    {user.title.charAt(0).toUpperCase()}
                </Typography>
            </Avatar>

            <Menu
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '12px',
                            backgroundColor: 'grey.50',
                            boxShadow: ' 0px 1px 8px 0px rgba(53, 53, 53, 0.25)',

                            '& > .MuiList-root': {
                                padding: '0 !important',
                                '& .MuiListItemIcon-root, & .MuiListItemText-root, & .MuiTypography-root, & .MuiListItemIcon-root': {
                                    color: 'inherit'
                                }
                            }
                        }
                    }
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
            >
                <Box p={2}>
                    <Stack alignItems="center" spacing={1} pb={2} sx={{ color: 'grey.800' }}>
                        <IconButton size="small" color="inherit" sx={{ alignSelf: 'flex-end' }} onClick={closeMenu}>
                            <ClearIcon />
                        </IconButton>
                        <EmployeeRoleChip role={user.employee.role.name} size="medium" />
                        <Typography variant="h4" sx={{ fontWeight: 600, fontSize: '1.5rem', lineHeight: '1.16', color: 'inherit' }}>
                            {`Hi, ${user?.title}`}
                        </Typography>
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                    <List
                        component="nav"
                        sx={{
                            padding: 0,
                            width: '100%',
                            maxWidth: '350px',
                            minWidth: '300px',
                            [theme.breakpoints.down('sm')]: {
                                minWidth: '100%'
                            },

                            '& .MuiListItemButton-root': {
                                color: 'grey.800',
                                lineHeight: '1.25rem',
                                fontSize: '0.875rem',
                                py: 1,
                                px: 1.5,
                                borderRadius: '8px',

                                '& .MuiListItemText-root + .MuiListItemIcon-root': {
                                    color: 'grey.300',
                                    minWidth: '24px'
                                },

                                '&.Mui-error': {
                                    color: 'error.dark',
                                    '& .MuiListItemIcon-root': {
                                        color: 'inherit'
                                    },

                                    '&:hover': {
                                        backgroundColor: 'error.light'
                                    }
                                },

                                '&:hover:not(.Mui-error)': {
                                    color: 'primary.900',
                                    backgroundColor: 'primary.light',

                                    '& .MuiListItemIcon-root': {
                                        color: 'inherit'
                                    }
                                },

                                '& .MuiListItemText-root': {
                                    margin: 0
                                }
                            }
                        }}
                    >
                        <ListItemButton selected={selectedIndex === 4} onClick={handleOpenSettings}>
                            <ListItemIcon>
                                <ManageAccounts />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Profile Settings</Typography>} />
                            <ListItemIcon>
                                <ChevronRightOutlinedIcon />
                            </ListItemIcon>
                        </ListItemButton>

                        {user.stripe_billing_portal_url && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <Link href={user.stripe_billing_portal_url} target="_blank" underline="none">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <IconCurrencyDollar stroke={1.5} size="1.3rem" />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography variant="body2">Billing Portal</Typography>} />
                                        <ListItemIcon>
                                            <ChevronRightOutlinedIcon />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Link>
                            </>
                        )}
                        <Divider sx={{ my: 1 }} />
                        <ListItemButton selected={selectedIndex === 4} onClick={handleLogout} className="Mui-error">
                            <ListItemIcon>
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                            <ListItemIcon>
                                <ChevronRightOutlinedIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </List>
                </Box>
            </Menu>
        </>
    );
};

export default ProfileSection;
