// material-ui
import { Button, Grid } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router';
import React, { useContext, useEffect } from 'react';
import { useAppDispatch } from '../../hooks/redux';

// assets
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import customerAPI from 'services/CustomerService';
import { ICustomerPayload } from 'models/ICustomer';
import CustomerFullForm from './CustomerFullForm';
import appointmentAPI from '../../services/AppointmentService';
import { AbilityContext } from '../../utils/roles/Can';
import useMobileCreateButton from '../../hooks/useMobileCreateButton';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import useCustomerFormDefaults from './hooks/use-customer-form-defaults';
import { skipToken } from '@reduxjs/toolkit/query';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';

const CustomerEdit: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ability = useContext(AbilityContext);

    useMobileCreateButton({
        action: () => navigate('/customer/create'),
        condition: ability.can('create', 'customer')
    });

    const { data, isFetching } = customerAPI.useGetCustomerQuery(id ?? skipToken, {
        refetchOnMountOrArgChange: true
    });
    const [updateCustomer] = customerAPI.useUpdateCustomerMutation();
    const { showSnackbar } = useShowSnackbar();

    const defaults = useCustomerFormDefaults(data);

    const handleUpdate = (customer: ICustomerPayload) => {
        if (id) {
            dispatch(startSubmitting());
            updateCustomer({ id: parseInt(id, 10), ...customer })
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: 'Customer updated',
                        alertSeverity: SnackBarTypes.Success
                    });
                    navigate('/customer', { replace: true });
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                })
                .catch((err) => {
                    showSnackbar({
                        message: err.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        }
    };

    useEffect(() => {
        if (!isFetching && !data) {
            navigate('/customer', { replace: true });
        }
    }, [data, isFetching, navigate]);

    return (
        <>
            {!isFetching && data && (
                <Grid>
                    <MainCard
                        title={`${data.firstname} ${data.lastname}`}
                        secondary={
                            <Button size="small" disableElevation onClick={() => navigate(-1)}>
                                <ChevronLeftOutlinedIcon />
                                Go back
                            </Button>
                        }
                        contentSX={{ p: { xs: 1.5, sm: 3 } }}
                    >
                        <CustomerFullForm customer={defaults} update={handleUpdate} />
                    </MainCard>
                </Grid>
            )}
        </>
    );
};

export default CustomerEdit;
