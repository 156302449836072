import { Alert, Box, MenuItem, TextField, Typography } from '@mui/material';
import StyledGreenButton from '../../views/scheduling-widget/components/StyledGreenButton';
import { FC, useCallback, useState } from 'react';
import CBModal from '../cb-modal/CBModal';
import { useTheme } from '@material-ui/core/styles';
import widgetService from '../../services/WidgetService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { useAppDispatch } from '../../hooks/redux';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';

interface WidgetWaitlistProps {
    company_slug: string;
    appointment_uuid: string;
    any_employee_enabled: boolean;
}

const WidgetWaitlist: FC<WidgetWaitlistProps> = ({ company_slug, appointment_uuid, any_employee_enabled }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const [join, { isLoading }] = widgetService.useJoinWaitlistMutation();

    const [open, setOpen] = useState(false);
    const [anyProvider, setAnyProvider] = useState(0);
    const [joined, setJoined] = useState(false);

    const handleJoinWaitList = useCallback(() => {
        dispatch(startSubmitting());
        join({ company_slug, appointment_uuid, any_employee: Boolean(anyProvider) })
            .unwrap()
            .then(() => {
                setOpen(false);
                setJoined(true);
            })
            .catch((err) => {
                showSnackbar({
                    message: err.message || JSON.stringify(err),
                    alertSeverity: SnackBarTypes.Error
                });
            })
            .finally(() => {
                dispatch(stopSubmitting());
            });
    }, [anyProvider, appointment_uuid, company_slug, dispatch, join, showSnackbar]);

    const handleClickJoinButton = useCallback(() => {
        any_employee_enabled ? setOpen(true) : handleJoinWaitList();
    }, [any_employee_enabled, handleJoinWaitList]);

    return (
        <Box py={2}>
            {joined ? (
                <Alert severity="success">You have joined the waitlist.</Alert>
            ) : (
                <>
                    <Typography component="h4" sx={{ fontSize: '18px', color: theme.palette.widget.text, mb: 1 }}>
                        Would you like to be notified if an appointment becomes available sooner?
                    </Typography>
                    <StyledGreenButton variant="contained" onClick={handleClickJoinButton} disabled={isLoading}>
                        Join Waitlist
                    </StyledGreenButton>
                </>
            )}

            <CBModal
                open={open}
                onClose={() => setOpen(false)}
                title="Join Waitlist"
                onClickOk={handleJoinWaitList}
                okButtonText="Join"
                fullWidth
                maxWidth="sm"
                okButtonDisabled={isLoading}
            >
                <TextField
                    disabled={isLoading}
                    fullWidth
                    aria-label="Provider Variants Selector"
                    select
                    value={anyProvider}
                    onChange={(e) => setAnyProvider(parseInt(e.target.value, 10))}
                >
                    <MenuItem value={0}>Same Service Provider</MenuItem>
                    <MenuItem value={1}>Earliest Available Provider</MenuItem>
                </TextField>
            </CBModal>
        </Box>
    );
};

export default WidgetWaitlist;
