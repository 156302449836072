import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack } from '@mui/material';

interface WidgetSubtitleProps {
    leftContent: React.ReactNode;
    slug?: string;
}

const WidgetSubtitleWithCustomerAuthLink: React.FC<WidgetSubtitleProps> = ({ leftContent, slug }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
            {leftContent}
            {slug && (
                <Link color="inherit" underline="none" variant="body1" component={RouterLink} to={`/customer-auth/${slug}`} target="_blank">
                    Log In as Customer
                </Link>
            )}
        </Stack>
    );
};

export default WidgetSubtitleWithCustomerAuthLink;
