import { FC, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { axiosServices } from '../../../utils/axios';
import { PaymentGateways } from '../../../types';
import { Alert } from '@mui/material';
import StyledRestartButton from '../styled/StyledRestartButton';
import Refresh from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router';

type SchedulingWidgetFinalizeProps = {
    company_slug: string;
    payment_intent_client_secret: string;
};

const SchedulingWidgetFinalize: FC<SchedulingWidgetFinalizeProps> = ({ company_slug, payment_intent_client_secret }) => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string | boolean>(false);
    const stripe = useStripe();
    const storageItem = localStorage.getItem(payment_intent_client_secret);
    const bookingData = storageItem ? JSON.parse(storageItem) : null;
    const navigate = useNavigate();

    useEffect(() => {
        if (stripe && bookingData && !success && !error) {
            stripe
                ?.retrievePaymentIntent(payment_intent_client_secret)
                .then((res) =>
                    axiosServices.post(`/public/company/${company_slug}/appointment`, {
                        ...bookingData,
                        payment_details: { ...res.paymentIntent, gateway: PaymentGateways.Stripe }
                    })
                )
                .then(() => {
                    setSuccess(true);
                    localStorage.removeItem(payment_intent_client_secret);
                })
                .catch((err) => {
                    console.error(err);
                    setError(err?.message || JSON.stringify(err));
                });
        }
    }, [bookingData, company_slug, error, payment_intent_client_secret, stripe, success]);

    return (
        <div>
            {!success && !error && <Alert severity="info">Finishing your payment, please wait...</Alert>}
            {success && <Alert severity="success">Appointment created successfully.</Alert>}
            {error && <Alert severity="error">{error}</Alert>}
            {(success || error) && (
                <StyledRestartButton onClick={() => navigate(`/cal/${company_slug}/`)}>
                    <Refresh />
                    Schedule another booking
                </StyledRestartButton>
            )}
        </div>
    );
};

export default SchedulingWidgetFinalize;
