import locationAPI from '../../services/LocationService';
import { useCallback, useMemo } from 'react';

const useLocationOptions = () => {
    const { data, isLoading, isFetching } = locationAPI.useFetchAllLocationsQuery({});

    const locations = useMemo(() => data?.data || [], [data]);

    const getNameById = useCallback((id: number) => locations.find((loc) => loc.id === id)?.name, [locations]);

    return { locations, isLoading, getNameById, isFetching };
};

export default useLocationOptions;
