import { useCallback } from 'react';
import { useAppDispatch } from '../../../../hooks/redux';
import customerAPI from '../../../../services/CustomerService';
import { stopSubmitting } from '../../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import { openConfirmPopup } from '../../../../store/confirmPopupSlice';
import { ICustomerNote, ICustomerNoteUpdatePayload } from '../../../../models/ICustomer';

const useCustomerNoteActions = (customerId: number | string) => {
    const [deleteNote, { isLoading: isDeleting }] = customerAPI.useDeleteCustomerNoteMutation();
    const [updateNote, { isLoading: isUpdating }] = customerAPI.useUpdateCustomerNoteMutation();
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();

    const handleError = useCallback(
        (err: any) => {
            showSnackbar({
                alertSeverity: SnackBarTypes.Error,
                message: err?.message ?? JSON.stringify(err)
            });
        },
        [showSnackbar]
    );

    const handleDeleteNote = useCallback(
        (id: number | string) => {
            deleteNote({ customerId, noteId: id }).unwrap().catch(handleError);
        },
        [customerId, deleteNote, handleError]
    );

    const onDeleteNote = useCallback(
        (id: number | string) => {
            dispatch(
                openConfirmPopup({
                    text: 'Delete this note?',
                    confirmText: 'Delete',
                    onConfirm: () => handleDeleteNote(id)
                })
            );
        },
        [dispatch, handleDeleteNote]
    );

    const onUpdateNote = useCallback(
        (data: ICustomerNoteUpdatePayload, onSuccess?: () => void) => {
            updateNote({ customerId, data })
                .unwrap()
                .then(() => {
                    onSuccess?.();
                })
                .catch(handleError)
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [customerId, dispatch, handleError, updateNote]
    );

    const toggleNotePin = useCallback(
        (note: ICustomerNote) => {
            const { id, text, is_pinned } = note;
            const data = { id, text, is_pinned: !is_pinned, images: undefined };
            onUpdateNote(data);
        },
        [onUpdateNote]
    );

    return { onDeleteNote, isDeleting, onUpdateNote, isUpdating, toggleNotePin };
};

export default useCustomerNoteActions;
