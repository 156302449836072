import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Theme, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
import { DefaultRootStateProps, LinkTarget, NavItemType } from 'types';
import { clearDeactivationToggle } from 'store/slices/deactivationToggleSlice';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const listIconSx = {
    minWidth: '18px',
    marginTop: 'auto',
    marginBottom: 'auto',
    color: 'inherit !important'
};

const menuIconSx = {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: 'inherit !important'
};

const listCustomIconSx = {
    marginTop: 'auto',
    marginBottom: 'auto'
};

const listCustomIconSubSx = {
    width: '6px',
    height: '6px'
};
const listCustomIconSubActiveSx = {
    width: '8px',
    height: '8px'
};

const listItemSx = {
    padding: '12px',
    alignItems: 'center',
    color: 'grey.800',

    '&:hover, &.Mui-selected': {
        color: 'primary.900',
        backgroundColor: 'primary.light'
    }
};

const subMenuCaptionSx = {
    fontSize: '0.6875rem',
    fontWeight: 500,
    color: 'grey.500',
    textTransform: 'capitalize'
};

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

export interface NavItemProps {
    item: NavItemType;
    level: number;
}

const NavItem = ({ item, level }: NavItemProps) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={customization.isOpen.findIndex((id) => id === item?.id) > -1 ? listCustomIconSubActiveSx : listCustomIconSubSx}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemIconSx = item?.icon ? listIconSx : menuIconSx;
    /**
     *nav-dark doesnt exist on navType
     *navType can only be 'light' | 'dark'
     */

    itemIconSx = customization.navType === 'dark' ? { ...itemIconSx, ...listCustomIconSx } : itemIconSx;

    const listItemProps = useMemo(() => {
        let itemTarget: LinkTarget = '_self';
        if (item.target) {
            itemTarget = '_blank';
        }

        if (item?.external) {
            return { component: 'a', href: item.url, target: itemTarget };
        }

        return { component: React.forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
    }, [item.external, item.target, item.url]);

    const itemHandler = (id: string) => {
        dispatch({ type: MENU_OPEN, id });
        matchesSM && dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    React.useEffect(() => {
        if (pathname === '/profile') {
            dispatch({ type: MENU_OPEN, id: null });
            return;
        }
        const currentIndex = pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{ borderRadius: `${customization.borderRadius}px`, ...listItemSx }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => {
                itemHandler(item.id!);
                dispatch(clearDeactivationToggle());
            }}
        >
            <ListItemIcon sx={itemIconSx} style={{ minWidth: level > 1 ? 18 : 36 }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                        color="inherit"
                        sx={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={subMenuCaptionSx} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
